import { Component, Vue, Ref } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { SCROLL_TO_TOP } from '@/store/actions';
import { windowOffsetPercent, windowOffset } from '@/store/getters';

@Component({})
export default class BackToTop extends Vue {
  @Getter(windowOffsetPercent) windowOffsetPercent!: number;
  @Getter(windowOffset) windowOffset!: number;

  @Action(SCROLL_TO_TOP) scrollToTop!: () => void;
  width = 70;
  radius = 34;

  get buttonShow() {
    return this.windowOffset > 350;
  }

  get circleStyle() {
    const length = 2 * Math.PI * this.radius;
    return {
      strokeDasharray: length,
      strokeDashoffset: length - length * this.windowOffsetPercent,
    };
  }
}
